<template>

  <div class="row" ref="divisionForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">

            <div class="row">
              <ValidationProvider
                vid="title"
                class="col-md-6"
                rules="required"
                name="The Title"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Title"
                          name="title"
                          fou
                          @keyup="generateURL"
                          v-model="formData.title">
                </fg-input>
              </ValidationProvider>
              <ValidationProvider
                vid="url"
                class="col-md-6"
                rules="required"
                name="The url"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="URL"
                          name="url"
                          fou
                          v-model="formData.url">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="">
              <ValidationProvider
                vid="phone"
                class="col-md-6"
                rules=""
                name="The Phone"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Phone"
                          name="phone"
                          fou
                          v-model="formData.phone">
                </fg-input>
              </ValidationProvider>
              <ValidationProvider
                vid="mail"
                class="col-md-6"
                rules=""
                name="The Mail"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Mail"
                          name="mail"
                          fou
                          v-model="formData.mail">
                </fg-input>
              </ValidationProvider>
              <ValidationProvider
                vid="facebook"
                class="col-md-6"
                rules=""
                name="The Facebook"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Facebook"
                          name="facebook"
                          fou
                          v-model="formData.facebook">
                </fg-input>
              </ValidationProvider>
            </div>

            <fg-text type="text"
                     label="Text"
                     name="text"
                     v-model="formData.text">
            </fg-text>
            <div class="row">
              <div class="col-md-6">
                <fg-input type="text"
                          label="Latitude"
                          name="latitude"
                          v-model="formData.latitude">
                </fg-input>
              </div>
              <div class="col-md-6">
                <fg-input type="text"
                          label="Longitude"
                          name="longitude"
                          v-model="formData.longitude">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input type="text"
                          label="Meta Title"
                          name="meta_title"
                          v-model="formData.meta_title">
                </fg-input>
              </div>
            </div>


            <div class="row">
              <div class="col-md-12">
                <fg-text type="text"
                         label="Meta Description"
                         name="meta_description"
                         v-model="formData.meta_description">
                </fg-text>
              </div>
            </div>

            <div class="col-md-6 d-flex justify-content-between">
              <div class="form-group pt-4">
                <label>Is Active</label>&nbsp;
                <l-switch v-model="formData.is_active">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
              </div>
            </div>
          </div>



          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/divisions/list/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Tooltip} from 'element-ui';
import {Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components'
import {PrimePdfUploader} from '@/components';
import FgSelect from "@/components/Inputs/formGroupSelect";
import PrimeVideoUploader from "../../components/PrimeVideoUploader";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    PrimeVideoUploader,
    LSwitch,
    [Tooltip.name]: Tooltip,
    PrimeUploader,
    PrimePdfUploader,
    'editor': Editor,
    FgSelect,
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      editorConfig: this.getEditorConfig(),

      formData: {
        title: "",
        url: "",
        text: "",
        phone: "",
        mail: "",
        is_active: true,
        lang: 'ar',
        meta_title: '',
        meta_description: '',
        latitude: '',
        longitude: '',
        facebook: ''
      },
    };
  },

  created(){
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.divisionForm
    });
    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Division";
      this.getDivision();
    } else {
      this.editMode = false;
      this.formTitle = "Add Division";
      this.loader.hide();
    }
  },


  methods: {
    getDivision() {
      let data = {
        'id' : this.id,
        'lang' : this.formData.lang
      };
      this.axios.post("divisions/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Division Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let fromData = {...this.formData};
      if (this.editMode === true) {
        request = this.axios.put("divisions/update/" + this.id, fromData);
        successMessage = "Division Updated Successfully";
      } else {
        request = this.axios.post("divisions/create", fromData);
        successMessage = "Division Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/divisions/list/"+ this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    getBannerInfo($key) {
      if(this.mediaInfo && this.mediaInfo[$key]) {
        return this.mediaInfo[$key];
      } else {
        return "";
      }
    },
    generateURL() {

      let newUrl = '';
      if (this.formData.title) {
        this.formData.meta_title = this.formData.title;
        this.formData.meta_description = this.formData.title;
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
    },

  }
}


</script>

<style>
</style>
